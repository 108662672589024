import { print } from 'graphql';
export class ChirpError extends Error {
  constructor(errorObj) {
    super(errorObj.chirpErrorMessage != null ? errorObj.chirpErrorMessage : 'Unknown CHIRP Error');
    this.chirpError = errorObj;
  }
}
export class ChirpInternalError extends Error {
  constructor(errors) {
    super('CHIRP GraphQL responded with errors');
    this.errors = errors;
  }
}

/**
 * Strips the __typename and _case fields (which are implementation details) from a response,
 * so we don't leak them to consumers. Mutates the object
 * @param all - the top level response object's _all property
 */
function removeGatewayMetadata(all) {
  if (typeof all === 'object' && all !== null) {
    // @ts-expect-error delete is declarative and works whether the property exists or not
    delete all.__typename;
    // @ts-expect-error delete is declarative and works whether the property exists or not
    delete all._case;
  }
  return all;
}
export function parseChirpGraphQLResponse(rawResponse) {
  const response = rawResponse;
  if (!response.data || Object.keys(response.data).length === 0) {
    throw new ChirpInternalError(response.errors);
  }
  const responseMessage = Object.values(response.data)[0]._all;
  if (responseMessage._case.value === 'OK') {
    return removeGatewayMetadata(responseMessage);
  } else if (responseMessage._case.value === 'ERROR') {
    const error = removeGatewayMetadata(responseMessage);
    throw new ChirpError(error);
  } else {
    throw new ChirpInternalError([removeGatewayMetadata(responseMessage)]);
  }
}
export function generateProductionPersistedQueries({
  operationName,
  query,
  variables,
  chirpGraphQLGatewayEndpoint
}) {
  const hash = query.id;
  if (!hash) {
    throw new Error('Expected `id` property on query AST');
  }
  const newEndpoint = `${chirpGraphQLGatewayEndpoint}/${hash}/${operationName}`;
  const data = {
    variables
  };
  return {
    chirpGraphQLGatewayEndpoint: newEndpoint,
    data
  };
}
export function generatePersistedQueries({
  operationName,
  query,
  variables,
  chirpGraphQLGatewayEndpoint
}) {
  if (process.env.NODE_ENV === 'production') {
    return generateProductionPersistedQueries({
      operationName,
      query,
      variables,
      chirpGraphQLGatewayEndpoint
    });
  } else {
    const data = {
      operationName,
      query: print(query),
      variables
    };
    return {
      chirpGraphQLGatewayEndpoint,
      data
    };
  }
}
export function formatChirpMSWSuccessResponse(mockResponse) {
  return {
    response: {
      _all: Object.assign({
        _case: {
          value: 'OK',
          __typename: 'GraphQLCaseOk'
        },
        __typename: 'success_query_response'
      }, mockResponse)
    }
  };
}
export function formatChirpMSWUserErrorResponse(mockErrorResponse) {
  return {
    response: {
      _all: Object.assign({
        _case: {
          __typename: 'GraphQLCaseError',
          value: 'ERROR'
        },
        __typename: 'user_error_query_response'
      }, mockErrorResponse)
    }
  };
}
export function formatChirpMSWInternalErrorResponse(mockErrorResponse) {
  return [...mockErrorResponse];
}